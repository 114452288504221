import React from "react";
import css from "./test.module.scss";

//icons
import { AiFillCheckCircle, AiOutlineCheck } from "react-icons/ai";
import Footer from "./Components/Footer/Footer"
import NavBar from "./Components/navbar/NavBar"


const Test = () => {
  return (
    <>
    <NavBar />
      <div className={css.wrapper}>
        <div className={css.background}>
          <div className={css.titlebox}>
            <h1>Web Development Prices & Plans</h1>
          </div>
          <div className={css.container}>
            <div className={`${css.panel} ${css["pricing-table"]}`}>
              <div className={css["pricing-plan"]}>
                <img
                  src="https://s22.postimg.cc/8mv5gn7w1/paper-plane.png"
                  alt=""
                  className={css["pricing-img"]}
                />
                <span className={css["pricing-price"]}>₹9,999 </span>
                <h2 className={css["pricing-header"]}>Portfolio websites </h2>

                <ul className={css["pricing-features"]}>
                  <li className={css["pricing-features-item"]}>
                    Dedicated Account Manager
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Fully Functional Backend/Frontend
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Mobile/Desktop Responsive Design
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Professional Website Design
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Premium Theme
                  </li>
                  <li className={css["pricing-features-item"]}>Fast Speed</li>
                  <li className={css["pricing-features-item"]}>
                    Smooth Integration
                  </li>
                  <li className={css["pricing-features-item"]}>Server Setup</li>
                  <li className={css["pricing-features-item"]}>
                    DNS Management
                  </li>
                  <li className={css["pricing-features-item"]}>Email Setup</li>
                  <li className={css["pricing-features-item"]}>
                    Graphic Design Upload
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Graphic Design Creation
                  </li>

                  <li className={css["pricing-features-item"]}>Contact Form</li>
                  <li className={css["pricing-features-item"]}>
                    Social media Link Integration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    1 Hour Training Post Delivery
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Mobile/Desktop Responsive Design
                  </li>

                  <li className={css["pricing-features-item"]}>
                    Professional Email and Integration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content Upload
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content Creation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    SEO Tool Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>Contact Form</li>
                  <li className={css["pricing-features-item"]}>Pages - 3-4</li>
                  <li className={css["pricing-features-item"]}>
                    YouTube Video Imbed (Provided by client)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Multiple Page Design Concept
                  </li>
                  <li className={css["pricing-features-item"]}>Wireframing</li>

                  <li className={css["pricing-features-item"]}>
                    Rotating Banner on Home Page
                  </li>
                  <li className={css["pricing-features-item"]}>
                    CMS (Self Manageable Site)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Manage Page/Image/Content
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Website Search
                  </li>

                  <li className={css["pricing-features-item"]}>Blog</li>
                  <li className={css["pricing-features-item"]}>
                    SSL Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Enquiry Form Submission
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Full Google Admin Configuration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Development Rev
                  </li>
                  <li className={css["pricing-features-item"]}>Testimonials</li>
                </ul>
              </div>

              <div className={css["pricing-plan"]}>
                <img
                  src="https://s28.postimg.cc/ju5bnc3x9/plane.png"
                  alt=""
                  className={css["pricing-img"]}
                />
                <span className={css["pricing-price"]}>$400</span>
                <h2 className={css["pricing-header"]}>E-Commerece Websites</h2>

                <ul className={css["pricing-features"]}>
                  <li className={css["pricing-features-item"]}>
                    Product Listing - Upto 50
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Fully Functional Backend/Frontend
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Mobile/Desktop Responsive Design
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Professional Website Design
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Premium Theme
                  </li>
                  <li className={css["pricing-features-item"]}>Fast Speed</li>
                  <li className={css["pricing-features-item"]}>
                    Smooth Integration
                  </li>
                  <li className={css["pricing-features-item"]}>Server Setup</li>
                  <li className={css["pricing-features-item"]}>
                    DNS Management
                  </li>
                  <li className={css["pricing-features-item"]}>Email Setup</li>
                  <li className={css["pricing-features-item"]}>
                    Graphic Design Upload
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Graphic Design Creation
                  </li>

                  <li className={css["pricing-features-item"]}>Contact Form</li>
                  <li className={css["pricing-features-item"]}>
                    Social media Link Integration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    1 Hour Training Post Delivery
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Mobile/Desktop Responsive Design
                  </li>

                  <li className={css["pricing-features-item"]}>
                    Professional Email and Integration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content Upload
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content Creation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    SEO Tool Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>Contact Form</li>
                  <li className={css["pricing-features-item"]}>Pages - 4-5</li>
                  <li className={css["pricing-features-item"]}>
                    YouTube Video Imbed (Provided by client)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Multiple Page Design Concept
                  </li>
                  <li className={css["pricing-features-item"]}>Wireframing</li>

                  <li className={css["pricing-features-item"]}>
                    Rotating Banner on Home Page
                  </li>
                  <li className={css["pricing-features-item"]}>
                    CMS (Self Manageable Site)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Manage Page/Image/Content
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Website Search
                  </li>

                  <li className={css["pricing-features-item"]}>Blog</li>
                  <li className={css["pricing-features-item"]}>
                    SSL Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content CreationEnquiry Form Submission
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Full Google Admin Configuration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Development Rev
                  </li>
                  <li className={css["pricing-features-item"]}>Testimonials</li>
                  <li className={css["pricing-features-item"]}>
                    YouTube Video Imbed (Provided by client)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Inquiries on Backend Panel
                  </li>

                  <li className={css["pricing-features-item"]}>Blog</li>
                  <li className={css["pricing-features-item"]}>
                    SSL Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Enquiry Form Submission
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Full Google Admin Configuration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Development Rev
                  </li>
                  <li className={css["pricing-features-item"]}>Testimonials</li>

                  <li className={css["pricing-features-item"]}>
                    Inquiries on Emails
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Site Map Creations
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Dedicated Account Manager
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Up-sells and cross-sells
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Catalog Management
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Product Search
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Product Filtering
                  </li>

                  <li className={css["pricing-features-item"]}>
                    Category Manager
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Discount Management
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Shopping Cart
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Multi Currency
                  </li>
                  <li className={css["pricing-features-item"]}>
                    DBulk upload of Products
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Level Category
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Woocommerce and tax set up
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Sale/Account Report
                  </li>
                </ul>
              </div>

              <div className={css["pricing-plan"]}>
                <img
                  src="https://s21.postimg.cc/tpm0cge4n/space-ship.png"
                  alt=""
                  className={css["pricing-img"]}
                />
                <span className={css["pricing-price"]}>$400</span>
                <h2 className={css["pricing-header"]}>
                  Premium Business Website
                </h2>
                <ul className={css["pricing-features"]}>
                  <li className={css["pricing-features-item"]}>
                    Product Listing - Upto 50
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Fully Functional Backend/Frontend
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Mobile/Desktop Responsive Design
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Professional Website Design
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Premium Theme
                  </li>
                  <li className={css["pricing-features-item"]}>Fast Speed</li>
                  <li className={css["pricing-features-item"]}>
                    Smooth Integration
                  </li>
                  <li className={css["pricing-features-item"]}>Server Setup</li>
                  <li className={css["pricing-features-item"]}>
                    DNS Management
                  </li>
                  <li className={css["pricing-features-item"]}>Email Setup</li>
                  <li className={css["pricing-features-item"]}>
                    Graphic Design Upload
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Graphic Design Creation
                  </li>

                  <li className={css["pricing-features-item"]}>Contact Form</li>
                  <li className={css["pricing-features-item"]}>
                    Social media Link Integration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    1 Hour Training Post Delivery
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Mobile/Desktop Responsive Design
                  </li>

                  <li className={css["pricing-features-item"]}>
                    Professional Email and Integration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content Upload
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content Creation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    SEO Tool Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>Contact Form</li>
                  <li className={css["pricing-features-item"]}>More Pages</li>
                  <li className={css["pricing-features-item"]}>
                    YouTube Video Imbed (Provided by client)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Multiple Page Design Concept
                  </li>
                  <li className={css["pricing-features-item"]}>Wireframing</li>

                  <li className={css["pricing-features-item"]}>
                    Rotating Banner on Home Page
                  </li>
                  <li className={css["pricing-features-item"]}>
                    CMS (Self Manageable Site)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Manage Page/Image/Content
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Website Search
                  </li>

                  <li className={css["pricing-features-item"]}>Blog</li>
                  <li className={css["pricing-features-item"]}>
                    SSL Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Content CreationEnquiry Form Submission
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Full Google Admin Configuration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Development Rev
                  </li>
                  <li className={css["pricing-features-item"]}>Testimonials</li>
                  <li className={css["pricing-features-item"]}>
                    YouTube Video Imbed (Provided by client)
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Inquiries on Backend Panel
                  </li>

                  <li className={css["pricing-features-item"]}>Blog</li>
                  <li className={css["pricing-features-item"]}>
                    SSL Implementation
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Enquiry Form Submission
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Full Google Admin Configuration
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Development Rev
                  </li>
                  <li className={css["pricing-features-item"]}>Testimonials</li>

                  <li className={css["pricing-features-item"]}>
                    Inquiries on Emails
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Site Map Creations
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Dedicated Account Manager
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Up-sells and cross-sells
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Catalog Management
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Product Search
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Product Filtering
                  </li>

                  <li className={css["pricing-features-item"]}>
                    Category Manager
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Discount Management
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Shopping Cart
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Multi Currency
                  </li>
                  <li className={css["pricing-features-item"]}>
                    DBulk upload of Products
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Level Category
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Woocommerce and tax set up
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Sale/Account Report
                  </li>

                  <li className={css["pricing-features-item"]}>
                    Newsletter Subscription
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Multi Language
                  </li>
                  <li className={css["pricing-features-item"]}>
                    User Registration and Management
                  </li>
                  <li className={css["pricing-features-item"]}>
                    Website Transfer to Live Environment
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

         <div className={css.digitalmarketing}>
          <div className={css.titlebox}>
            <h1>Digital Marketing Prices & Plans</h1>
          </div>
          <div className={css.cards}>
            <div className={css.card}>
              <div className={css.header}>
                <p className={css.title}>Startup</p>
                <div className={css["price-container"]}>
                  <span>₹</span>8,999
                  <span>/</span>
                  <span>onwards</span>
                </div>
              </div>

              <ul className={css.lists}>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Instagram Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post – 20</li>
                      <li className={css["sub-list"]}>- Reels – 4</li>
                      <li className={css["sub-list"]}>
                        {" "}
                        - Stories Uploading -1/day
                      </li>
                      <li className={css["sub-list"]}>- Hashtag Research</li>
                      <li className={css["sub-list"]}>
                        - Post Creative Designing
                      </li>
                      <li className={css["sub-list"]}>- Audience Research</li>
                    </ul>
                  </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Facebook Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post – 30</li>
                      <li className={css["sub-list"]}>- Reels – 5</li>
                      <li className={css["sub-list"]}>
                        - Stories Uploading -2/day
                      </li>
                      <li className={css["sub-list"]}>- Hashtag Research</li>
                      <li className={css["sub-list"]}>
                        - Post Creative Designing
                      </li>
                      <li className={css["sub-list"]}>- Business Manager </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
            <div className={css.card}>
              <div className={css.header}>
                <p className={css.title}>Business</p>
                <div className={css["price-container"]}>
                  <span>₹</span>10,999
                  <span>/</span>
                  <span>onwards</span>
                </div>
              </div>

              <ul className={css.lists}>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>Account Creation</span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span> Account Management</span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span> Account optimization </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Instagram Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post – 20</li>
                      <li className={css["sub-list"]}>- Reels – 4</li>
                      <li className={css["sub-list"]}>
                        {" "}
                        - Stories Uploading -1/day
                      </li>
                      <li className={css["sub-list"]}>- Hashtag Research</li>
                      <li className={css["sub-list"]}>
                        - Post Creative Designing
                      </li>
                      <li className={css["sub-list"]}>- Audience Research</li>
                    </ul>
                  </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Facebook Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post – 30</li>
                      <li className={css["sub-list"]}>- Reels – 5</li>
                      <li className={css["sub-list"]}>
                        - Stories Uploading -2/day
                      </li>
                      <li className={css["sub-list"]}>- Hashtag Research</li>
                      <li className={css["sub-list"]}>
                        - Post Creative Designing
                      </li>
                      <li className={css["sub-list"]}>- Business Manager </li>
                    </ul>
                  </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Google My Business(GMB)
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>
                        - Create Business Profile
                      </li>
                      <li className={css["sub-list"]}>
                        - Add Contact Information
                      </li>
                      <li className={css["sub-list"]}>
                        - Write A Business Description
                      </li>
                      <li className={css["sub-list"]}>
                        - Publish Google Posts – 3/week
                      </li>
                      <li className={css["sub-list"]}>- Upload New Photos</li>
                      <li className={css["sub-list"]}>
                        - Add/Update Products/Services 
                      </li>

                      <li className={css["sub-list"]}>
                        - Review Submission- 12/month 
                      </li>
                    </ul>
                  </span>
                </li>{" "}
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    LinkedIn Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post creation- 1</li>
                      <li className={css["sub-list"]}>- Post sharing- 2</li>
                      <li className={css["sub-list"]}>- Connection Building</li>
                      <li className={css["sub-list"]}>
                        - Campaign Manager Account Set Up
                      </li>
                      <li className={css["sub-list"]}>
                        - Linkedin Insight Tag Installation
                      </li>
                      <li className={css["sub-list"]}>
                        - Creation Of Custom Conversion  
                      </li>
                      <li className={css["sub-list"]}>- Ads Monitoring</li>
                      <li className={css["sub-list"]}>- Monthly Reporting  </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
            <div className={css.card}>
              <div className={css.header}>
                <p className={css.title}>Master</p>
                <div className={css["price-container"]}>
                  <span>₹</span>17,999
                  <span>/</span>
                  <span>onwards</span>
                </div>
              </div>

              <ul className={css.lists}>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>Account Creation</span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span> Account Management</span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span> Account optimization </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Instagram Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post – 20</li>
                      <li className={css["sub-list"]}>- Reels – 4</li>
                      <li className={css["sub-list"]}>
                        {" "}
                        - Stories Uploading -1/day
                      </li>
                      <li className={css["sub-list"]}>- Hashtag Research</li>
                      <li className={css["sub-list"]}>
                        - Post Creative Designing
                      </li>
                      <li className={css["sub-list"]}>- Audience Research</li>
                    </ul>
                  </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Facebook Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post – 30</li>
                      <li className={css["sub-list"]}>- Reels – 5</li>
                      <li className={css["sub-list"]}>
                        - Stories Uploading -2/day
                      </li>
                      <li className={css["sub-list"]}>- Hashtag Research</li>
                      <li className={css["sub-list"]}>
                        - Post Creative Designing
                      </li>
                      <li className={css["sub-list"]}>- Business Manager </li>
                    </ul>
                  </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Google My Business(GMB)
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>
                        - Create Business Profile
                      </li>
                      <li className={css["sub-list"]}>
                        - Add Contact Information
                      </li>
                      <li className={css["sub-list"]}>
                        - Write A Business Description
                      </li>
                      <li className={css["sub-list"]}>
                        - Publish Google Posts – 3/week
                      </li>
                      <li className={css["sub-list"]}>- Upload New Photos</li>
                      <li className={css["sub-list"]}>
                        - Add/Update Products/Services 
                      </li>

                      <li className={css["sub-list"]}>
                        - Review Submission- 12/month 
                      </li>
                    </ul>
                  </span>
                </li>

                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    LinkedIn Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>- Post creation- 1</li>
                      <li className={css["sub-list"]}>- Post sharing- 2</li>
                      <li className={css["sub-list"]}>- Connection Building</li>
                      <li className={css["sub-list"]}>
                        - Campaign Manager Account Set Up
                      </li>
                      <li className={css["sub-list"]}>
                        - Linkedin Insight Tag Installation
                      </li>
                      <li className={css["sub-list"]}>
                        - Creation Of Custom Conversion  
                      </li>
                      <li className={css["sub-list"]}>- Ads Monitoring</li>
                      <li className={css["sub-list"]}>- Monthly Reporting  </li>
                    </ul>
                  </span>
                </li>
                <li className={css.list}>
                  <AiFillCheckCircle className={css.icon} />
                  <span>
                    Youtube Marketing
                    <ul className={css["sub-lists"]}>
                      <li className={css["sub-list"]}>
                        - Video Creation – 4/month
                      </li>
                      <li className={css["sub-list"]}>
                        - Video Editing Add Contact Information
                      </li>
                      <li className={css["sub-list"]}>- Video scheduling</li>
                      <li className={css["sub-list"]}>- Video uploading</li>
                      <li className={css["sub-list"]}>- Thumbnail Creation</li>
                      <li className={css["sub-list"]}>
                        - Youtube Ad Account Set Up 
                      </li>

                      <li className={css["sub-list"]}>
                        - Skipable In-Stream Ads
                      </li>
                      <li className={css["sub-list"]}>- Bumper Ads</li>
                      <li className={css["sub-list"]}>
                        - Detailed Audience Creation
                      </li>
                      <li className={css["sub-list"]}>
                        - Custom Audience Creation 
                      </li>

                      <li className={css["sub-list"]}>
                        - A/B Testing Of Targeting
                      </li>
                      <li className={css["sub-list"]}>
                        - Remarketing Video Ads
                      </li>
                      <li className={css["sub-list"]}>- Discovery Ads </li>

                      <li className={css["sub-list"]}>
                        - Video Ads With Promotion Of Your Products
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={css.seo}>
          <div className={css.seocontainer}>
            <div className={css.seoimg}>
              <img
                src="https://img.freepik.com/free-vector/ppc-campaign-abstract-concept-illustration_335657-3747.jpg?w=740&t=st=1690464926~exp=1690465526~hmac=150b1cdbc114210bd7905c6e51a3066075b62f4dcb4d7887a87c6938aafff3fb"
                alt=""
              />
            </div>
            <div className={css.seoprice}>
            <div className={css.titleBoxSeo}>
            <div className={css.pricetitle}>
                <div className={css.cardseo}>
                 
                  <div className={css.data}>
                    <p>Ads & SEO Plans
                  </p>
                  
                  </div>
                  <div className={css.titleseo}>
                    <span className={css.symb}>₹</span>
                    <p className={css["title-text-seo"]}>21,999 <span>     /</span></p>
                    <span className={css.onwards}>onwards</span>
                  </div>
                </div>
              </div>
              
            </div>
            <div className={css.det}>
           <div className={css.detcard}>
            <h3>Facebook Ads</h3>
            <ul>
              <li><AiFillCheckCircle  className={css.ics} /> Ads Management – Upto 5 Campaigns</li>
              <li><AiFillCheckCircle className={css.ics} /> Ads design and creation</li>
              <li><AiFillCheckCircle className={css.ics} /> Facebook shopping ads
</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad optimization</li>
              <li><AiFillCheckCircle className={css.ics}/> Campaign strategy and Set Up
</li>
              <li><AiFillCheckCircle className={css.ics}/> Campaign monitoring</li>
              <li><AiFillCheckCircle className={css.ics}/> Ad’s Monitoring and Management</li>
              <li><AiFillCheckCircle className={css.ics}/> Ads setup</li>
              <li><AiFillCheckCircle className={css.ics} /> Audience Reach</li>
              <li><AiFillCheckCircle className={css.ics}/> Ad’s Running time – As per the Client</li>
              <li><AiFillCheckCircle className={css.ics}/> Ad Budget Extra – Provided by Client</li>
            </ul>
           </div>
           <div className={css.detcard}>
            <h3>Google Ads</h3>
            <ul>
              <li> <AiFillCheckCircle className={css.ics} /> Ads Management – Upto 2 Campaigns
</li>
              <li> <AiFillCheckCircle className={css.ics} /> Ads design and creation</li>
              <li> <AiFillCheckCircle className={css.ics} /> Google shopping ads

</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad optimization</li>
              <li><AiFillCheckCircle className={css.ics} /> Campaign strategy and Set Up
</li>
              <li><AiFillCheckCircle className={css.ics} /> Campaign monitoring</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad’s Monitoring and Management</li>
              <li><AiFillCheckCircle className={css.ics} /> Ads setup</li>
              <li><AiFillCheckCircle className={css.ics} /> Audience Reach</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad’s Running time – As per the Client</li>
              <li><AiFillCheckCircle className={css.ics} />Ad Budget Extra – Provided by Client</li>
            </ul>
           </div>
              </div>
              

            </div>
          </div>
          <div className={css.seoinfo}>
          <div className={css.title}>
            Search Engine optimization (SEO) Plans
          </div>
          <div className={css.detcols}>
          <div className={css.detcol}>
       
            <ul>
              <li> <AiFillCheckCircle className={css.ics} /> Ads Management – Upto 2 Campaigns
</li>
              <li> <AiFillCheckCircle className={css.ics} /> Ads design and creation</li>
              <li> <AiFillCheckCircle className={css.ics} /> Google shopping ads

</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad optimization</li>
              <li><AiFillCheckCircle className={css.ics} /> Campaign strategy and Set Up
</li>
              <li><AiFillCheckCircle className={css.ics} /> Campaign monitoring</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad’s Monitoring and Management</li>
              <li><AiFillCheckCircle className={css.ics} /> Ads setup</li>
              <li><AiFillCheckCircle className={css.ics} /> Audience Reach</li>
              <li><AiFillCheckCircle className={css.ics} /> Ad’s Running time – As per the Client</li>
              <li><AiFillCheckCircle className={css.ics} />Ad Budget Extra – Provided by Client</li>
            </ul>
           </div>
           <div className={css.detcol}>
       
       <ul>
         <li> <AiFillCheckCircle className={css.ics} /> Ads Management – Upto 2 Campaigns
</li>
         <li> <AiFillCheckCircle className={css.ics} /> Ads design and creation</li>
         <li> <AiFillCheckCircle className={css.ics} /> Google shopping ads

</li>
         <li><AiFillCheckCircle className={css.ics} /> Ad optimization</li>
         <li><AiFillCheckCircle className={css.ics} /> Campaign strategy and Set Up
</li>
         <li><AiFillCheckCircle className={css.ics} /> Campaign monitoring</li>
         <li><AiFillCheckCircle className={css.ics} /> Ad’s Monitoring and Management</li>
         <li><AiFillCheckCircle className={css.ics} /> Ads setup</li>
         <li><AiFillCheckCircle className={css.ics} /> Audience Reach</li>
         <li><AiFillCheckCircle className={css.ics} /> Ad’s Running time – As per the Client</li>
         <li><AiFillCheckCircle className={css.ics} />Ad Budget Extra – Provided by Client</li>
       </ul>
      </div>
      <div className={css.detcol}>
       
       <ul>
         <li> <AiFillCheckCircle className={css.ics} /> Ads Management – Upto 2 Campaigns
</li>
         <li> <AiFillCheckCircle className={css.ics} /> Ads design and creation</li>
         <li> <AiFillCheckCircle className={css.ics} /> Google shopping ads

</li>
         <li><AiFillCheckCircle className={css.ics} /> Ad optimization</li>
         <li><AiFillCheckCircle className={css.ics} /> Campaign strategy and Set Up
</li>
         <li><AiFillCheckCircle className={css.ics} /> Campaign monitoring</li>
         <li><AiFillCheckCircle className={css.ics} /> Ad’s Monitoring and Management</li>
         <li><AiFillCheckCircle className={css.ics} /> Ads setup</li>
         <li><AiFillCheckCircle className={css.ics} /> Audience Reach</li>
         <li><AiFillCheckCircle className={css.ics} /> Ad’s Running time – As per the Client</li>
         <li><AiFillCheckCircle className={css.ics} />Ad Budget Extra – Provided by Client</li>
       </ul>
      </div>
      <div id={css.detcol4} className={css.detcol}>
  
  <ul>
    <li> <AiFillCheckCircle className={css.ics} /> Ads Management – Upto 2 Campaigns
</li>
    <li> <AiFillCheckCircle className={css.ics} /> Ads design and creation</li>
    <li> <AiFillCheckCircle className={css.ics} /> Google shopping ads

</li>
    <li><AiFillCheckCircle className={css.ics} /> Ad optimization</li>
    <li><AiFillCheckCircle className={css.ics} /> Campaign strategy and Set Up
</li>
    <li><AiFillCheckCircle className={css.ics} /> Campaign monitoring</li>
    <li><AiFillCheckCircle className={css.ics} /> Ad’s Monitoring and Management</li>
    <li><AiFillCheckCircle className={css.ics} /> Ads setup</li>
    <li><AiFillCheckCircle className={css.ics} /> Audience Reach</li>
    <li><AiFillCheckCircle className={css.ics} /> Ad’s Running time – As per the Client</li>
    <li><AiFillCheckCircle className={css.ics} />Ad Budget Extra – Provided by Client</li>
  </ul>
 </div>
          </div>

                
              </div>
        </div>
      </div>
     <Footer />
    </>
  );
};

export default Test;

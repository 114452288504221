import React from "react";
import css from "./Contact.module.scss";
import { MdPhoneIphone, MdEmail } from "react-icons/md";
import { TiLocation } from "react-icons/ti";

const ContactUs = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.titleBox}>
        <h1>
          Contact <span>Us</span>
        </h1>
        <p>
        Welcome to promotion villa if you need any help
then simply send this message or call, we are
online and ready to help.
        </p>
      </div>
      <div className={css.contactBox}>
        <div className={css.contactInfo}>
          <div className={css.contactInfotitle}>
            <h3>Contact Us</h3>
            <p>
              Please leave Your Message, our team will get back to you within 24
              hours.
            </p>
          </div>
          <div className={css.contactInfodetails}>
            {/** info 1 */}
            <div className={css.info}>
              <i>
                {" "}
                <MdPhoneIphone />
              </i>
              <h4>
                Phone <br />
                <span>+91-93520-09934</span>,
                <span>+91 9610756856</span>
              </h4>
            </div>

            {/** info 2 */}
            <div className={css.info}>
              <i>
                {" "}
                <MdEmail />
              </i>
              <h4>
                Email <br />
                <span>promotionvilla11@gmail.com</span>
              </h4>
            </div>
            {/** info 3 */}
            <div className={css.info}>
              <i>
                {" "}
                <TiLocation />
              </i>
              <h4>
               Office Address <br />
                <span>Opp. Royal Palace, Nawalgarh Road <br/> Sikar (Raj) 332001</span>
              </h4>
            </div>
          </div>
        </div>
        <div className={css.contactForm}>
          <div className={css.formrow1}>
            <input id="name" name="name" type="text" placeholder="Name Here" />
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email Here"
            />
          </div>
          <div className={css.formrow2}>
            <input
              id="number"
              name="number"
              type="text"
              placeholder="Phone Number "
            />
            <input
              id="subect"
              name="subject"
              type="text"
              placeholder="Subject Here"
            />
          </div>
          <div className={css.formrow3}>
            <textarea
              className={css.textarea}
              rows="5"
              id="message"
              placeholder="Message"
            />
          </div>
          <button>
            <div className={css.svgwrapper1}>
              <div className={css.svgwrapper}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    fill="currentColor"
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  ></path>
                </svg>
              </div>
            </div>
            <span>Send</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import "./Hero.scss";
import { Link as ScrollLink } from "react-scroll";

const handleScrollToContacts = () => {
  // Check if the current location is the home page
  if (window.location.pathname === "/") {
    // Scroll to the "contact" element in the Home component
    const element = document.getElementById("contacts");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  } else {
    // Navigate to the home page first
    window.location.href = "/#contacts";
  }
};
const handleScrollToWhyUs = () => {
  // Check if the current location is the home page
  if (window.location.pathname === "/") {
    // Scroll to the "contact" element in the Home component
    const element = document.getElementById("why");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  } else {
    // Navigate to the home page first
    window.location.href = "/#why";
  }
};

const Hero = () => {
  return (
    <>
      <div className="hero-wrapper">
        <div className="hero-btns">
          <div className="left-btn">
            <ScrollLink
              to="contacts"
              smooth={true}
              offset={-85}
              duration={1000}
              activeClass="active"
              onClick={handleScrollToContacts}
            >
              <button>
                <span>Contact Us</span>
              </button>
            </ScrollLink>
          </div>
          <div className="right-btn">
            <ScrollLink
              to="why"
              smooth={true}
              offset={-85}
              duration={1000}
              activeClass="active"
              onClick={handleScrollToWhyUs}
            >
              <button>
                <span>Why choose us</span>
              </button>
            </ScrollLink>
          </div>
        </div>

        <div className="hero-title">
          <h2>PV</h2>
        </div>
        <div className="hero-info">
          <h1>CREATIVITY MEETS TECHNOLOGY</h1>
          <p>
            Welcome to Promotion villa, the best digital marketing company in
            Jaipur. Here, we do all the digital or promotion work your business
            needs to grow online.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;

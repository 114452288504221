import React from 'react'
import css from "./graph.module.scss";
import NavBar from "../navbar/NavBar";
import Footer from "../Footer/Footer";
import { HiArrowRightCircle } from "react-icons/hi2";

const Graphics = () => {
  return (
    <>
    <NavBar />
    <div className={css.wrapper}>
      <div className={css.titlebox}>
        <div className={css.title}>
         
        </div>
      </div>
      <div className={css.main}>
        <div className={css.mainhero}>
          <div className={css.mainHerotext}>
            <h1>Captivate Your Audience with Striking Visual Storytelling</h1>

            <p>
            Embrace the power of compelling visuals that speak volumes about your brand. Our creative graphic design solutions will breathe life into your ideas, leaving a lasting impression on your audience and setting you apart from the competition.
            </p>
          </div>
          <div className={css.mainHerogif}>
            <iframe
              className={css.webGIF}
              src="https://lottie.host/?file=08cf57c2-1909-45d8-ae0c-96112ce2246c/kmNQEL1T6K.json"
            ></iframe>
          </div>
        </div>
      </div>
      <div className={css.container}>
          <div className={css.intro}>
            <div className={css.introimg}>
              <img
                src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1574075519-smm1.jpg"
                alt=""
              />
            </div>
            <div className={css.introtext}>
              <p>
                {" "}
                We love expressing stories visually and turning concepts into
enthralling designs. For both digital and print media, we
specialize in producing aesthetically beautiful graphics. We
bring your idea to life with attention to detail and a great eye for
aesthetics in everything from logos and branding materials to
marketing collateral and social media graphics. Our objective is
to develop designs that not only enthrall your audience but also
successfully convey the essence of your business. Let&#39;s work
together to enhance your brand and leave a lasting impression
by unleashing the power of graphic design.
              </p>
            </div>
          </div>
          <div className={css.typecontainer}>
            <div className={css.types}>
              <h1>Types of Services of graphic designing</h1>
              <ul>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Brochure Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Digital Brochure Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Company Profile Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Packaging Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Exhibition Stall Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Indoor-Outdoor Promotions
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Visual Aid Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Signage Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Wall Graphics Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Vehicle Graphics
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Magazine Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Flyers Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Poster Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Calendar Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Certificate Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Shopping Bag Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Menu Card Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  ID Card Design
                </li>
              </ul>
            </div>
            <div className={css.img}>
              <img
                src="https://assets.website-files.com/5f22271f4a92a90a8198c6ef/5f580e61444e9cfab4e04276_28%20(1).png"
                alt=""
              />
            </div>
          </div>
        </div>
    </div>
    <Footer />
  </>
  )
}

export default Graphics
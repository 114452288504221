import React from "react";
import styles from "./Adv.module.scss";
import { Link as ScrollLink } from "react-scroll";

const Adv = () => {
  const handleScrollToContacts = () => {
    // Check if the current location is the home page
    if (window.location.pathname === "/") {
      // Scroll to the "contact" element in the Home component
      const element = document.getElementById("contacts");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Navigate to the home page first
      window.location.href = "/#contacts";
    }
  };
  return (
    <div className={styles.Wrapper}>
      <div className={styles.title}>
        <span>We Offer</span>
        <h1>Advertisment</h1>
        <hr />
      </div>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.img}>
            <img
              src="https://img.freepik.com/free-psd/outdoor-billboard-city_15879-108.jpg?w=1060&t=st=1687874958~exp=1687875558~hmac=e1160ab82290e73755b4c45957ea0eaf017b85bf0499d043784bb7ec5de11c80"
              alt=""
            />
          </div>
          <div className={styles.text}>
            <h1>Outdoor Marketing</h1>
            <p>
              {" "}
              we specialize is in providing effective outdoor
advertising solutions that capture audiences&#39; attention
and provide results. We make sure your brand is seen
by a lot of people in high-traffic areas by strategically
placing billboards, transit ads, and signage. Our outdoor
advertisements capture onlookers and make an
impression with their clear messaging and attention-
grabbing imagery. To improve brand awareness and
optimize brand exposure, we provide targeted
campaigns, imaginative designs, and cutting-edge
technologies. Let&#39;s work together to maximize the
effectiveness of outdoor advertising to improve brand
awareness and leave a lasting impression.
            </p>
           
          </div>
        </div>

        <div id={styles.box2} className={styles.box}>
          <div className={styles.text}>
            <h1>T.V & PRESS Advertisment</h1>
            <p>
              {" "}
              We are experts at producing compelling TV and print ads that
appeal to your target market. We attract audiences and make
an impact with fascinating content and aesthetically stunning
visuals. Our press advertisements deliver targeted messaging
in newspapers and magazines, while our TV commercials

engage and connect with viewers through the power of
audiovisual storytelling. We broaden your brand&#39;s reach and
generate results through strategic placement and unique
content. With the help of our TV and newspaper advertising
services, let&#39;s work together to raise the bar for your
advertising.
            </p>
         
          </div>
          <div className={styles.img}>
            <img
              src="https://img.freepik.com/free-photo/man-watching-show-his-tv_23-2149047401.jpg?w=1060&t=st=1687876298~exp=1687876898~hmac=77a48aecfef4ded442908eee282bac09f847747b8bcbcadd46f85cc934035121"
              alt=""
            />
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.img}>
            <img
              src="https://img.freepik.com/free-photo/japanese-subway-train-system-display-screen-passenger-information_23-2148954835.jpg?w=996&t=st=1687876680~exp=1687877280~hmac=4d20082b711af1359b5fba4e96a5f9dc174ad810ee8bfbc67ba0fbae57c40c77"
              alt=""
            />
          </div>
          <div className={styles.text}>
            <h1>Digital Wallscreen</h1>
            <p>
              {" "}
              We specialize in producing attention-grabbing, interactive
digital wall screen advertisements for use in public settings. We
engage viewers and create enduring brand experiences by
utilizing dynamic content, colorful visuals, and cutting-edge
technology. Through real-time updates and customized content,
our strategic approach ensures that your campaigns will be as
effective as possible. We harness the power of digital wall
screens to connect and engage a variety of audiences, whether
through fascinating movies, engaging games, or tailored
information. In order to leave a lasting impression in the always
changing world of advertising, let&#39;s work together and leverage
the power of digital wall screens.
            </p>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adv;

import React from "react";
import css from "./why.module.scss";
import expertise from "../../Assets/quality.png";
import result from "../../Assets/evaluation.png";
import innovative from "../../Assets/idea.png";
import creative from "../../Assets/brain.png";

const Why = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.titleBox}>
        <div className={css.title}>
          <span>know more</span>
          <h1>Why Choose Promotion Villa?</h1>
          <hr />
        </div>
        <div className={css.titlePara}>
          <p>
            Your company's reputation is crucial and delicate as glass. We work
            to improve the image of your brand and market it in a way that makes
            it shine.
          </p>
          <button>
            <p>Get In Touch</p>
            <svg
              stroke-width="4"
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 5l7 7m0 0l-7 7m7-7H3"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
            </svg>
          </button>{" "}
        </div>
      </div>
      <div className={css.main}>
        <div className={css.cards}>
          <div id={css.card1} className={css.card}>
            <img src={expertise} alt="" />
            <div className={css.prp}>
              <h1>Expertise</h1>
              <p>
                We have a fantastic team of professionals who have years of
                expertise in the sector, which is why we are so good at what we
                do.
              </p>
            </div>
          </div>
          <div id={css.card2} className={css.card}>
            <img src={result} alt="" />
            <div className={css.prp}>
              <h1>Result Driven</h1>
              <p>
                We are a premier marketing firm that is results- driven and
                committed to giving our customers quantifiable outcomes.
              </p>
            </div>
          </div>
          <div id={css.card3} className={css.card}>
            <img src={innovative} alt="" />
            <div className={css.prp}>
              <h1>Innovative </h1>
              <p>
                We swiftly adapt new technology, trends, and processes because
                we are inventive and like to keep one step ahead of the
                competition.
              </p>
            </div>
          </div>
          <div id={css.card4} className={css.card}>
            <img src={creative} alt="" />
            <div className={css.prp}>
              <h1>Creative</h1>
              <p>
                We take fresh ideas and put them together to create something
                beautiful and utterly original.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;

import React from "react";
import css from "./social.module.scss";
import NavBar from "../navbar/NavBar";
import Footer from "../Footer/Footer";
import img from "../../Assets/bg1_hero.jpg";
import { HiArrowRightCircle } from "react-icons/hi2";

const SocialMedia = () => {
  return (
    <>
      <NavBar />
      <div className={css.wrapper}>
        <div className={css.titlebox}>
          <div className={css.title}>
      
          </div>
        </div>
        <div className={css.main}>
          <div className={css.mainhero}>
            <div className={css.mainHerotext}>
              <h1>
                Connect, Engage, and Thrive with Targeted Social Media Marketing
              </h1>

              <p>
                Ignite meaningful conversations and build lasting relationships
                with your audience through our expert social media marketing
                services. Let's create a powerful social media presence that
                drives results and amplifies your brand's influence.
              </p>
            </div>
            <div className={css.mainHerogif}>
              <iframe
                className={css.webGIF}
                src="https://lottie.host/?file=0320287e-b670-4b07-9739-f8c166f77471/RO7uJdxCZG.json"
              ></iframe>
            </div>
          </div>
        </div>
        <div className={css.container}>
          <div className={css.intro}>
            <div className={css.introimg}>
              <img src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1574075519-smm1.jpg" alt="" />
            </div>
            <div className={css.introtext}>
              <p>
                {" "}
                Expand the Influence of Your Brand. We specialize in maximizing
                your brand&#39;s online presence and driving engagement on
                social media platforms using a planned approach. We use the
                strength of social media platforms to engage with your target
                audience, increase brand awareness, and create meaningful
                connections, from creating intriguing content to putting
                targeted advertising campaigns into place. we want to raise your
                online presence, gain more followers, and produce quality leads
                for you with data-driven insights and original techniques.
                Together, let&#39;s maximize social media&#39;s potential and
                grow your brand to new heights.
              </p>
            </div>
          </div>
          <div className={css.typecontainer}>
          <div className={css.types}>
            <h1> Types of social media services</h1>
            <ul>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i> Organic Social Media Marketing Social Media Analytics and
                Reporting
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Paid Social Media Advertising
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Influencer Marketing
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Social Media Contests and Giveaways
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Social Media Influencer Takeovers
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Social Listening and Monitoring
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Social Media Influencer Partnerships
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Social Media Live Streaming
              </li>
              <li>
                {" "}
                <i>
                  <HiArrowRightCircle />
                </i>{" "}
                Social Media Community Management
              </li>
            </ul>
          </div>
          <div className={css.img}>
            <img src="https://assets.website-files.com/5f22271f4a92a90a8198c6ef/5f580e61444e9cfab4e04276_28%20(1).png" alt="" />
          </div>
          </div>
         
        </div>
      </div>
   <Footer />
    </>
  );
};

export default SocialMedia;

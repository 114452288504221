import React from "react";
import css from "./digitalmark.module.scss";
import NavBar from "../navbar/NavBar";
import Footer from "../Footer/Footer";
import { HiArrowRightCircle } from "react-icons/hi2";

const DigMarketing = () => {
  return (
    <>
      <NavBar />
      <div className={css.wrapper}>
        <div className={css.titlebox}>
          <div className={css.title}></div>
        </div>
        <div className={css.main}>
          <div className={css.mainhero}>
            <div className={css.mainHerotext}>
              <h1>
                Unlock Your Online Success with Powerful Digital Marketing
                Strategies
              </h1>
              <p>
                Harness the full potential of digital marketing to elevate your
                brand's online presence. Our data-driven strategies and
                innovative approach will propel your business towards unrivaled
                growth and success in the digital landscape.
              </p>
            </div>
            <div className={css.mainHerogif}>
              <iframe
                className={css.webGIF}
                src="https://lottie.host/?file=ea8a13a3-ee3e-4721-a154-39ea8b538453/sSWBVBbOfp.json"
              ></iframe>
            </div>
          </div>
        </div>
        <div className={css.container}>
          <div className={css.intro}>
            <div className={css.introimg}>
              <img
                src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1574075519-smm1.jpg"
                alt=""
              />
            </div>
            <div className={css.introtext}>
              <p>
                {" "}
                We provide comprehensive solutions with knowledge in digital
                marketing to support businesses&#39; success online. We cover a
                range of topics, including search engine optimization (SEO),
                pay-per-click (PPC) advertising, content marketing, and more,
                from strategic planning to implementation. We craft plans to
                target your particular audience and get quantifiable outcomes by
                utilizing data-driven insights and cutting-edge tactics.
                our services are created to raise your digital presence and help
                you reach your marketing objectives, whether they be to increase
                website traffic, increase conversions, or improve brand
                visibility. Let&#39;s work together to leave a lasting
                impression in the digital world.
              </p>
            </div>
          </div>
          <div className={css.typecontainer}>
            <div className={css.types}>
              <h1>Types of digital marketing</h1>
              <ul>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Search Engine Optimization (SEO)
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Pay-Per-Click Advertising (PPC)
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Social Media Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Content Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Email Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Influencer Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Affiliate Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Mobile Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Video Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Conversion Rate Optimization (CRO)
                </li>
              </ul>
            </div>
            <div className={css.img}>
              <img
                src="https://assets.website-files.com/5f22271f4a92a90a8198c6ef/5f580e61444e9cfab4e04276_28%20(1).png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DigMarketing;

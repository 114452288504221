import React from 'react'
import css from "./video.module.scss";
import NavBar from "../navbar/NavBar";
import Footer from "../Footer/Footer";
import { HiArrowRightCircle } from "react-icons/hi2";

const Video = () => {
  return (
    <>
    <NavBar />
    <div className={css.wrapper}>
      <div className={css.titlebox}>
        <div className={css.title}>
          
        </div>
      </div>
      <div className={css.main}>
        <div className={css.mainhero}>
          <div className={css.mainHerotext}>
            <h1>Visual Storytelling: Unleashing the Power of Emotion and Imagination</h1>
            
            <p>
            Turn your visions into awe-inspiring realities through our exceptional video and photography services. From captivating storytelling to visually stunning imagery, we'll help you communicate your brand's essence in a way that tugs at heartstrings and drives engagement.
            </p>
          </div>
          <div className={css.mainHerogif}>
            <iframe
              className={css.webGIF}
              src="https://lottie.host/?file=857c6240-e72d-4e1c-ba6f-911a979b95d0/dstbYKIK5q.json"
            ></iframe>
          </div>
        </div>
      </div>
      <div className={css.container}>
          <div className={css.intro}>
            <div className={css.introimg}>
              <img
                src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1574075519-smm1.jpg"
                alt=""
              />
            </div>
            <div className={css.introtext}>
              <p>
                {" "}
                Making Your Brand Shine. We specialize in creating captivating
visuals that elevate your advertising campaigns. With expertise
in videography and photography, we bring your brand to life
through compelling content. From high-quality product
photography to engaging video advertisements, we capture the
essence of your brand and deliver impactful visuals that
resonate with your target audience. Our goal is to create
stunning visuals that effectively communicate your message
and drive customer engagement. Let&#39;s collaborate and take
your advertising to the next level with our videography and
photography services.
              </p>
            </div>
          </div>
          <div className={css.typecontainer}>
            <div className={css.types}>
              <h1>Types of Photography and videography services</h1>
              <ul>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Corporate Video Production
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Script Writing &amp; Video Editing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Motion Graphics
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Explainer Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Video Presentations
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Learning Development Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Animation Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Training Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Marketing Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Client Testimonials
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Employee Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Product Services Demo Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Promotional Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Event Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Company Profile Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Education Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Facebook &amp; Social Media Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Financial Services Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Recruitment Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Student Recruitment Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Professional Services Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Brand Ambassador Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Beauty &amp; Healthcare Videos
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Real Estate Video Production
                </li>
                
              </ul>
            </div>
            <div className={css.img}>
              <img
                src="https://assets.website-files.com/5f22271f4a92a90a8198c6ef/5f580e61444e9cfab4e04276_28%20(1).png"
                alt=""
              />
            </div>
          </div>
        </div>
    
    </div>
  </>
  )
}

export default Video
import React from "react";
import styles from "./ServiceIntro.module.scss";
import { NavLink } from "react-router-dom";

const ServiceIntro = () => {
  return (
    <div className={styles.serviceIntrowrapper}>
      <div className={styles.serviceIntrotitle}>
        <span>Explore more</span>
        <h1>What We Have For You!</h1>
        <hr />
      </div>
      <div className={styles.cards}>
        <article className={styles.card}>
          <div className={styles.temporarytext}>
            <img
              src="https://img.freepik.com/free-photo/professional-programmer-working-late-dark-office_1098-18705.jpg?w=1060&t=st=1687867281~exp=1687867881~hmac=ceb12a0703792d2705ac97edf0ac295cf0e414f368afef4808e64e392e5c3dca"
              alt=""
            />
          </div>
          <div className={styles.cardcontent}>
            <span className={styles.cardtitle}>Web & App Development</span>
            <span className={styles.cardsubtitle}>
            Transforming Ideas into Stunning Websites and
effective apps.
            </span>
            <p className={styles.carddescription}>
            We provide specialized solutions to turn your concepts into
breathtaking online experiences. Our services are tailored to
your particular requirements and range from user-friendly
websites that fascinate visitors to effective mobile apps that
engage your audience.
            </p>
            <button className={styles.cta}>
              <span className={styles.hoverunderlineanimation}>
                {" "}
             <NavLink to="/Web-Devlopment">
                Learn More{" "}
                </NavLink>
              </span>

              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                ></path>
              </svg>
            </button>
          </div>
        </article>
        {/** article 2 */}
        <article className={styles.card}>
          <div className={styles.temporarytext}>
            <img
              src="https://img.freepik.com/free-photo/pile-3d-popular-social-media-logos_1379-881.jpg?w=1060&t=st=1687867669~exp=1687868269~hmac=4f187a6f064c90cacac741f888d275f5d4b8a42960380a487a98be422b234947"
              alt=""
            />
          </div>
          <div className={styles.cardcontent}>
            <span className={styles.cardtitle}>Social Media Marketing</span>
            <span className={styles.cardsubtitle}>
            Driving Engagement and Expanding Reach:
Unleashing the Power of Social Media
Marketing
            </span>
            <p className={styles.carddescription}>
            Expand the Influence of Your Brand. We specialize in
maximizing your brand&#39;s online presence and driving
engagement on social media platforms using a planned
approach. We use the strength of social media platforms to
engage with your target audience, increase brand awareness.
            </p>
            <button className={styles.cta}>
              <span className={styles.hoverunderlineanimation}>
              <NavLink to="/Social-Marketing">
                Learn More{" "}
                </NavLink>
              </span>
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                ></path>
              </svg>
            </button>
          </div>
        </article>
        {/** article 3 */}
        <article className={styles.card}>
          <div className={styles.temporarytext}>
            <img
              src="https://img.freepik.com/free-photo/map-lying-wooden-table_53876-105723.jpg?w=1060&t=st=1687868049~exp=1687868649~hmac=0aa54aea24ee0a79613e477bdb6ea034f49989a3230ff684c211f8708e5f0fa0"
              alt=""
            />
          </div>
          <div className={styles.cardcontent}>
            <span className={styles.cardtitle}>Digital Marketing</span>
            <span className={styles.cardsubtitle}>
            Navigating the Digital Landscape: Strategies
and Tactics for Successful Digital Marketing
            </span>
            <p className={styles.carddescription}>
            We provide comprehensive solutions with knowledge in digital
marketing to support businesses&#39; success online. We cover a
range of topics, including search engine optimization (SEO),
pay-per-click (PPC) advertising, content marketing, and more,
from strategic planning to implementation.
            </p>
            <button className={styles.cta}>
              <span className={styles.hoverunderlineanimation}>
              <NavLink to="/Digital-Marketing">
                Learn More{" "}
                </NavLink>
              </span>
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                ></path>
              </svg>
            </button>
          </div>
        </article>

        {/** article 4 */}
        <article className={styles.card}>
          <div className={styles.temporarytext}>
            <img
              src="https://img.freepik.com/free-photo/brand-price-tags-style-graphics_53876-120302.jpg?w=1060&t=st=1687868149~exp=1687868749~hmac=607f8d1ddd3b7121bf678312933e0a3503a9545fd30d585faa41bc00bd8d2402 "
              alt=""
            />
          </div>
          <div className={styles.cardcontent}>
            <span className={styles.cardtitle}>Branding & Promotion</span>
            <span className={styles.cardsubtitle}>
            Making an Impactful Brand and Lighting Up
Promotions: Forging Lasting Impression
            </span>
            <p className={styles.carddescription}>
            We are experts in creating distinctive brand identities and
potent marketing campaigns that differentiate your company.
We assist you in building a powerful and enduring brand
presence through everything from logo creation and visual
branding to developing unified marketing strategies.
            </p>
            <button className={styles.cta}>
              <span className={styles.hoverunderlineanimation}>
              <NavLink to="/Branding">
                Learn More{" "}
                </NavLink>
              </span>
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                ></path>
              </svg>
            </button>
          </div>
        </article>
        {/** article 5 */}
        <article className={styles.card}>
          <div className={styles.temporarytext}>
            {" "}
            <img
              src="https://img.freepik.com/free-vector/add-color-concept-illustration_114360-5494.jpg?w=740&t=st=1687868246~exp=1687868846~hmac=0bb260411ff48c80f2686615dd3571afff6e93b89d6ac8733a3d1b45b26bb253"
              alt=""
            />
          </div>
          <div className={styles.cardcontent}>
            <span className={styles.cardtitle}>Graphics Designing</span>
            <span className={styles.cardsubtitle}>
            Unleashing Creative Excellence: Designing
Visual Narratives with Graphic Design
            </span>
            <p className={styles.carddescription}>
            We love expressing stories visually and turning concepts into
enthralling designs. For both digital and print media, we
specialize in producing aesthetically beautiful graphics. We
bring your idea to life with attention to detail and a great eye for aesthetics in everything from logos and branding materials.
            </p>
            <button className={styles.cta}>
              <span className={styles.hoverunderlineanimation}>
              <NavLink to="/Graphics-Designing">
                Learn More{" "}
                </NavLink>
              </span>
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                ></path>
              </svg>
            </button>
          </div>
        </article>
        {/** article 6 */}
        <article className={styles.card}>
          <div className={styles.temporarytext}>
            <img
              src="https://img.freepik.com/free-photo/young-woman-blogger-recording-video-camera_1303-27223.jpg?w=1060&t=st=1687868360~exp=1687868960~hmac=a827ccfd51533958a4fc4b4cf231e54ae6c8f23bd3bd1dff4e794cdf20a19918"
              alt=""
            />
          </div>
          <div className={styles.cardcontent}>
            <span className={styles.cardtitle}> Video & Photography</span>
            <span className={styles.cardsubtitle}>
            Using video and photography to their full
potential
            </span>
            <p className={styles.carddescription}>
            Making Your Brand Shine. We specialize in creating captivating
visuals that elevate your advertising campaigns. With expertise
in videography and photography, we bring your brand to life
through compelling content. From high-quality product
photography to engaging video advertisements.
            </p>
            <button className={styles.cta}>
              <span className={styles.hoverunderlineanimation}>
              <NavLink to="/Video&Photo">
                Learn More{" "}
                </NavLink>
              </span>
              <svg
                viewBox="0 0 46 16"
                height="10"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                id="arrow-horizontal"
              >
                <path
                  transform="translate(30)"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  data-name="Path 10"
                  id="Path_10"
                ></path>
              </svg>
            </button>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ServiceIntro;

import React from "react";
import css from "./About.module.scss";
import NavBar from "../../Components/navbar/NavBar";
import Footer from "../../Components/Footer/Footer";

export const About = () => {
  return (
    <>
      <NavBar />
      <div className={css.wrapper}>
        <div className={css.container}>
          <div className={css.titlebox}>
            <div className={css.title}>
              <h1>About Us</h1>
              <hr />
              <br />
              <p>
              Welcome to Promotion Villa, Your Powerhouse for Digital Marketing!
Utilize our strategic digital solutions to help your brand reach its full
potential. Together, let's dominate the digital world!
              </p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#fff"
                fill-opacity="1"
                d="M0,0L48,0C96,0,192,0,288,5.3C384,11,480,21,576,42.7C672,64,768,96,864,138.7C960,181,1056,235,1152,224C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>{" "}
          </div>
          <div className={css.content}>
            <div className={css.whoweare}>
              <h1>Who We Are</h1>
              <hr />
              <p>
              Welcome to the promotion villa.As a leading digital marketing
and advertisement company, we are committed to helping you
achieve your business goals. Our team of experts combines
strategic thinking, creativity, and technical expertise to deliver
comprehensive solutions tailored to your specific needs. From
developing effective digital marketing strategies to creating
compelling ad campaigns across various platforms, we
leverage the power of data-driven insights and innovative
techniques to enhance your brand&#39;s visibility, engage your
target audience, and drive measurable results. With our
customer-centric approach and dedication to your success, we
are your trusted partner in navigating the dynamic digital
landscape. Let&#39;s embark on a transformative digital journey
together and unlock your business&#39;s full potential.
              </p>
            </div>
            <div className={css.whatwedo}>
              <h1>What We Do</h1>
              <hr />
              <p>
              Strategic planning, search engine optimization (SEO),Branding
and promotions, web and app development, advertising, social
media management, content production, and other services are
all part of our wide variety of offerings that are personalized to
your needs. Our team of professionals works carefully to
improve your brand&#39;s visibility, attract relevant visitors, and
produce worthwhile leads. We emphasize increasing your
return on investment and creating quantifiable growth with a
results-driven approach. Allow us to be your digital partner as
we help your company reach new heights in the rapidly
changing digital environment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

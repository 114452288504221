import React from "react";
import css from "./webdev.module.scss";
import NavBar from "../navbar/NavBar";
import Footer from "../Footer/Footer";

const WebDev = () => {
  return (
    <>
      <NavBar />
      <div className={css.wrapper}>
        <div className={css.titlebox}>
          <div className={css.title}>
            <h1> Web-Development Services</h1>
            <hr />
            <br />
            <p>
            Prepare yourself to bring your ideas to life. Get in touch with us right
now for excellent web and app development services that produce
results.
            </p>
          </div>
        </div>
        <div className={css.main}>
          <div className={css.mainhero}>
            <div className={css.mainHerotext}>
              <h1>A Website is the Start of the Online Journey</h1>
              <h1>Online Journey</h1>
              <p>
                Let’s put your business on the online grounds to reach its
                maximum potential.
              </p>
            </div>
            <div className={css.mainHerogif}>
              <iframe
                className={css.webGIF}
                src="https://embed.lottiefiles.com/animation/118785"
              ></iframe>
            </div>
          </div>
        </div>
        <div className={css.webtypebox}>
          <div className={css.webTypeTitle}>
            <h1>
              We <span>Offer</span>
            </h1>
          </div>
          <div className={css.webTypeCards}>
            <div className={css.webTypeCard}>
              {/** card 1 start */}
              <div className={css.card}>
                <div className={css.cardimage}>
                  <img
                    src="https://img.freepik.com/free-psd/bank-design-template-web_23-2149197278.jpg?w=1380&t=st=1688463025~exp=1688463625~hmac=ae24af72be0a4873efa81379340f0eff1724e08bd3f4ab3e1c55615364a32a44"
                    alt=""
                  />
                </div>
                <p className={css.cardtitle}>PortFolio/Landing Websites</p>
                <p className={css.cardbody}>
                  Showcase your work with our stunning portfolio websites. Our
                  design experts will create visually captivating platforms to
                  highlight your talents and attract potential clients.
                </p>
              </div>
              {/** card 1 end */}
              {/** card 2 start */}
              <div className={css.card}>
                <div className={css.cardimage}>
                  <img
                    src="https://img.freepik.com/free-psd/digital-marketing-landing-page-template_23-2148924859.jpg?w=1380&t=st=1688464669~exp=1688465269~hmac=33b49117be30937e81c69e7617a617dfd8cd650a11f4c0f283e787d84921ac3f"
                    alt=""
                  />
                </div>
                <p className={css.cardtitle}>Corporate Websites</p>
                <p className={css.cardbody}>
                  Make a powerful online presence with our professional
                  corporate websites. We develop sleek and informative platforms
                  that effectively communicate your brand message, products, and
                  services.
                </p>
              </div>
              {/** card 2 end */}
              {/** card 3 start */}
              <div className={css.card}>
                <div className={css.cardimage}>
                  <img
                    src="https://img.freepik.com/free-psd/e-commerce-furniture-landing-page_197582-336.jpg?w=996&t=st=1688464380~exp=1688464980~hmac=b6a1ac5668cc361d100198e3001fe939a98215d87783294e1426d2b3ed337d3d"
                    alt=""
                  />
                </div>
                <p className={css.cardtitle}>E-Commerce Websites</p>
                <p className={css.cardbody}>
                  Explore our range of e-commerce websites that are tailored for
                  online shopping. From user-friendly interfaces to secure
                  payment gateways, we create platforms that drive sales and
                  enhance customer experiences.
                </p>
              </div>
              {/** card 3 end */}
              {/** card 4 start */}
              <div className={css.card}>
                <div className={css.cardimage}>
                  <img
                    src="https://img.freepik.com/free-vector/flat-minimal-international-school-landing-page-template_23-2149265068.jpg?w=1060&t=st=1688464771~exp=1688465371~hmac=17039e69e267dc8f8d6f34ae03ea67385f04c05e190ebaba341b19bf573074b4"
                    alt=""
                  />
                </div>
                <p className={css.cardtitle}>Educational Websites</p>
                <p className={css.cardbody}>
                  Revolutionize online learning with our educational websites.
                  Whether you're an institution or an individual educator, we
                  can create interactive platforms to deliver courses, share
                  resources, and engage learners.
                </p>
              </div>
              {/** card 4 end */}
            </div>
          </div>
        </div>
        <div className={css.specsBox}>
          <div className={css.specsMain}>
            <div id={css.row1} className={css.specsrow}>
              <div className={css.rowimg}>
                <img
                  src="https://techsharks.in/wp-content/uploads/2023/05/Easy-To-Customise.webp"
                  alt=""
                />
              </div>
              <div className={css.rowinfo}>
                <h1>Easy To Customize</h1>
                <p>
                  We create secure and easy-to-customize websites that let you
                  easily update the content yourself using a user-friendly
                  administration panel.
                </p>
              </div>
            </div>
            <div id={css.row2} className={css.specsrow}>
              <div className={css.rowimg}>
                <img
                  src="https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                  alt=""
                />
              </div>
              <div className={css.rowinfo}>
                <h1>User Friendly </h1>
                <p>
                  Each and every website made under Techshark’s roof is
                  user-friendly and best interacts with the users which builds
                  trust and credibility.
                </p>
              </div>
            </div>
            <div id={css.row3} className={css.specsrow}>
              <div className={css.rowimg}>
                <iframe src="https://embed.lottiefiles.com/animation/78798"></iframe>
              </div>
              <div className={css.rowinfo}>
                <h1>Responsive</h1>
                <p>
                  It’s important for a website to work well on both mobile and
                  desktop, and we make sure yours does.
                </p>
              </div>
            </div>
            <div id={css.row4} className={css.specsrow}>
              {" "}
              <div className={css.rowimg}>
                <iframe src="https://embed.lottiefiles.com/animation/123805"></iframe>
              </div>
              <div className={css.rowinfo}>
                <h1>Performance-Driven Optimization</h1>
                <p>
                Optimize your website's performance with Techshark's meticulous approach. Our development team fine-tunes every aspect to deliver blazing-fast load times, keeping your visitors engaged and conversions soaring.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default WebDev;

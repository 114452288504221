import React from 'react'
import css from "./brand.module.scss";
import NavBar from "../navbar/NavBar";
import Footer from "../Footer/Footer";
import { HiArrowRightCircle } from "react-icons/hi2";

const Branding = () => {
  return (
    <>
      <NavBar />
      <div className={css.wrapper}>
        <div className={css.titlebox}>
          <div className={css.title}></div>
        </div>
        <div className={css.main}>
          <div className={css.mainhero}>
          <div className={css.mainHerotext}>
            <h1>Crafting a Distinct Identity: Your Key to Lasting Impressions</h1>
           
            <p>
            Shape a strong and memorable brand identity that resonates with your target audience. Our strategic branding and promotions will elevate your brand's perception, build trust, and fuel a captivating narrative that stands out in the market.
            </p>
          </div>
            <div className={css.mainHerogif}>
              <iframe
                className={css.webGIF}
                src="https://lottie.host/?file=c4480730-f8f4-4807-86d1-cb9901cfd557/dWzLEipsn7.json"
              ></iframe>
            </div>
          </div>
        </div>
        <div className={css.container}>
          <div className={css.intro}>
            <div className={css.introimg}>
              <img
                src="https://dvyvvujm9h0uq.cloudfront.net/com/articles/1574075519-smm1.jpg"
                alt=""
              />
            </div>
            <div className={css.introtext}>
              <p>
                {" "}
                We are experts in creating distinctive brand identities and
potent marketing campaigns that differentiate your company.
We assist you in building a powerful and enduring brand
presence through everything from logo creation and visual
branding to developing unified marketing strategies. Our
specialized approaches are made to include your target market,
improve brand recognition, and encourage patronage. We are
committed to creating outstanding outcomes that take your
brand to new heights with a focus on creativity, strategy, and
innovation. Let&#39;s work together to make a positive impression in
the cutthroat business world.
              </p>
            </div>
          </div>
          <div className={css.typecontainer}>
            <div className={css.types}>
              <h1>Types of branding and promotion services</h1>
              <ul>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Brand Identity Design- logos, typography, color palettes, and visual elements
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Graphic Design
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Content Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Social Media Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Influencer Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Public Relations (PR)
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Advertising Campaigns
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Experiential Marketing
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Brand Consultation
                </li>
                <li>
                  {" "}
                  <i>
                    <HiArrowRightCircle />
                  </i>{" "}
                  Digital Marketing
                </li>
              </ul>
            </div>
            <div className={css.img}>
              <img
                src="https://assets.website-files.com/5f22271f4a92a90a8198c6ef/5f580e61444e9cfab4e04276_28%20(1).png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Branding
import React, { useEffect, useState } from "react";
import "./Nav.scss";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../../Assets/LogoMain.png";
import { NavLink } from "react-router-dom";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

//icons

const NavBar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [activeLink, setActiveLink] = useState("home");

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isVisible = prevScrollPos > currentScrollPos;

    setPrevScrollPos(currentScrollPos);
    setVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  const navbarContainerStyle = {
    position: "fixed",
    top: 0,
    width: "100%",
    transform: visible ? "translateY(0)" : "translateY(-90%)",
    transition: "transform 0.3s ease-in-out",
    zIndex: 999,
  };

  const handleScrollToService = () => {
    // Check if the current location is the home page
    if (window.location.pathname === "/") {
      // Scroll to the "contact" element in the Home component
      const element = document.getElementById("service");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Navigate to the home page first
      window.location.href = "/#service";
    }
  };

  return (
    <div className="nav-main-container" style={navbarContainerStyle}>
      <Navbar className="boot-nav" expand="lg">
        <Container fluid>
          <Navbar.Brand>
            <NavLink to="/">
              {" "}
              <a className="logo-main-nav">
                {" "}
                <img src={Logo} alt="" />{" "}
              </a>
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{
                maxHeight: "500px",
                fontSize: "1.5rem",
              }}
              navbarScroll
            >
              <NavLink to="/">
                <Nav.Link
                  href="#action1"
                  className={activeLink === "home" ? "active" : ""}
                  onClick={() => setActiveLink("home")}
                >
                  Home
                </Nav.Link>
              </NavLink>
              <NavLink to="/about">
                {" "}
                <Nav.Link
                  href="#action2"
                  className={activeLink === "about" ? "active" : ""}
                  onClick={() => setActiveLink("about")}
                >
                  About
                </Nav.Link>
              </NavLink>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavLink to="/Web-Devlopment">
                  <NavDropdown.Item href="#action/3.1">
                    Web & App Development
                  </NavDropdown.Item>{" "}
                </NavLink>
                <NavLink to="/Social-Marketing">
                  <NavDropdown.Item href="#action/3.2">
                    Social Media Marketing
                  </NavDropdown.Item>{" "}
                </NavLink>
                <NavLink to="/Digital-Marketing">
                  <NavDropdown.Item href="#action/3.3">
                    Digital Marketing
                  </NavDropdown.Item>{" "}
                </NavLink>

                <NavLink to="/Branding">
                  {" "}
                  <NavDropdown.Item href="#action/3.4">
                    Branding & Promotions
                  </NavDropdown.Item>
                </NavLink>
                <NavLink to="/Graphics-Designing">

                <NavDropdown.Item href="#action/3.4">
                  Graphics Designing
                </NavDropdown.Item></NavLink>
                <NavLink to="/Video&Photo">

                <NavDropdown.Item href="#action/3.4">
                  Video & Photography
                </NavDropdown.Item>
                </NavLink>
              </NavDropdown>
              <NavLink to="/plans">
                <Nav.Link
                  href="#action3"
                  className={activeLink === "services" ? "active" : ""}
                  onClick={() => setActiveLink("services")}
                >
                  Plans & Prices
                </Nav.Link>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;

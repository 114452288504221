import NavBar from "../../Components/navbar/NavBar";
import Hero from "../../Components/Hero/Hero";
import ServiceIntro from "../../Components/Serviceintro/ServiceIntro";
import Adv from "../../Components/Ad/Adv";
import Why from "../../Components/why/Why";
import ContactUs from "../../Components/contact/ContactUs";
import Footer from "../../Components/Footer/Footer";
import Price from "../../Components/price/Price";
import { Element } from "react-scroll";

const Home = () => {
  return (
    <div className="home">
      <NavBar />
      <Hero />
      <Element id="service" name="services">
        {" "}
        <ServiceIntro />{" "}
      </Element>

      <Adv />
      <Element id="why" name="why">
        <Why />
      </Element>

      <Price />
      <Element id="contact" name="contacts">
        <ContactUs />
      </Element>
      <Footer />
    </div>
  );
};

export default Home;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import { About } from "./Pages/about/About";
import PlansNprices from "./Pages/Plans&Prices/PlansNprices";

import WebDev from "./Components/Services/WebDev";
import DigMarketing from "./Components/Services/DigMarketing";
import ServiceIntro from "./Components/Serviceintro/ServiceIntro";
import Branding from "./Components/Services/Branding";
import Graphics from "./Components/Services/Graphics";
import Video from "./Components/Services/Video";
import SocialMedia from "./Components/Services/SocialMedia";
import Test from "./Test";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/plans" element={<Test />} />
          <Route path="/services" element={<Test />} />
          <Route path="/Web-Devlopment" element={<WebDev />} />
          <Route path="/Digital-Marketing" element={<DigMarketing />} />

          <Route path="/Social-Marketing" element={<SocialMedia />} />

          <Route path="/Branding" element={<Branding />} />
          <Route path="/Graphics-Designing" element={<Graphics />} />
          <Route path="/Video&Photo" element={<Video />} />
        </Routes>
      </BrowserRouter>{" "}
    </div>
  );
}

export default App;
